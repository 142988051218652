<template>
  <div>
    <div class="mb-2">
      <h2
        v-if="!editCompany"
      >
        <span
          v-b-hover="handleHover"
          class="text-uppercase cursor-pointer especial_border"
          :class="!isHovered ? 'p-0' : ''"
          @click="editCompany = true"
        >{{ nameCompany }}</span>
      </h2>
      <div
        v-if="editCompany"
        style="width: 40%"
        class="d-flex justify-content-between align-items-center"
      >
        <v-select
          v-model="selectedCompany"
          style="width: 100%"
          :options="companies"
          label="business_name"
          class="d-inline"
          no-caret
          @input="onChangeCompany"
        /><tabler-icon
          class="text-primary d-inline ml-1 cursor-pointer"
          icon="CircleXIcon"
          size="35"
          @click="editCompany=false; isHovered=false"
        />
      </div>
    </div>
    <b-nav
      pills
      class="m-0"
    >
      <b-nav-item
        v-b-popover.hover.top="'DASHBOARD'"
        :to="{ name: 'dashboard-company' }"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
        class="px-half"
        exact
      >DASHBOARD
      </b-nav-item>
      <b-nav-item
        :to="{name: 'category-company'}"
        exact
        exact-active-class="active border-radius-tabs"
        class="px-half"
        :link-classes="['px-3',bgTabsNavs]"
      >CATEGORIES</b-nav-item>
      <b-nav-item
        :to="{name: 'category-expenses'}"
        exact
        class="px-half"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
      >EXPENSES
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'incomes-company' }"
        exact
        class="px-half"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
      >INCOMES</b-nav-item>
      <b-nav-item
        :to="{ name: 'bankAccount-company' }"
        exact
        class="px-half"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
      >BANK ACCOUNTS</b-nav-item>
      <b-nav-item
        exact
        :class="countTransferSchedule> 0? 'pl-half' : 'px-half'"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
        :to="{ name: 'schedule-transfer-company' }"
      >SCHEDULE TRANSFERS
        <span
          v-if="countTransferSchedule>0"
          class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
          style="font-size: 90% !important; margin-left: 179px; margin-top: -2px"
        >
          {{ countTransferSchedule }}
        </span>
      </b-nav-item>
      <b-nav-item
        exact
        class="px-half"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
        :to="{ name: 'companies-files' }"
      >FILES</b-nav-item>
      <b-nav-item
        :to="{ name: 'profit-loss' }"
        exact
        class="px-half"
        exact-active-class="active border-radius-tabs"
        :link-classes="['px-3',bgTabsNavs]"
      >REPORT PROFIT & LOSS
      </b-nav-item>
    </b-nav>
    <router-view :key="cont" />
  </div>
</template>

<script>
import CompaniesService from '@/views/management/views/companies/companies.service'
import vSelect from 'vue-select'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      idCompany: '',
      nameCompany: '',
      editCompany: false,
      companies: [],
      selectedCompany: [],
      cont: 1,
      isHovered: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',

    }),
    ...mapState({

      countTransferSchedule: state => state.ManagementCompaniesStore.S_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER,

    }),

  },
  async created() {
    this.idCompany = this.$route.params.id
    const data = await CompaniesService.getCompanyById({
      company_id: this.idCompany,
    })
    this.nameCompany = data.data[0].name
    await this.getCompanies()

    try {
      await Promise.all([
        this.$store.dispatch('ManagementCompaniesStore/A_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER', {
          company_id: Number(this.$route.params.id),
          responsable: this.currentUser.user_id,
        }),

      ])
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered
    },
    async getCompanies() {
      try {
        const data = await CompaniesService.getOtherCompanies({
          id: this.idCompany,
        })
        this.companies = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },

    async onChangeCompany() {
      const { id } = this.selectedCompany
      await this.$router.push({ name: this.$route.name, params: { id } })
      this.nameCompany = this.selectedCompany.business_name
      this.editCompany = false
      this.isHovered = false
      this.cont++
      this.idCompany = this.$route.params.id
      await this.getCompanies()
      await this.$store.dispatch('ManagementCompaniesStore/A_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER', {
        company_id: Number(this.$route.params.id),
        responsable: this.currentUser.user_id,
      })
    },
  },
}
</script>

<style scoped>

.nav_prueba{
  color: white;
  margin-bottom: 10px ;
  border: 5px solid white;
  padding: 8px !important;
  height: 45px !important;
  width: 45px !important;
  border-radius:100% !important;
  background: #c6c4c4 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.bg-active .nav_prueba{
  color: white;
  margin-bottom: 10px ;
  border: 5px solid white;
  padding: 8px !important;
  height: 45px !important;
  width: 45px !important;
  border-radius:100% !important;
  background: #0090e7 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.px-half a{
  padding-left: 1.3rem !important;
  padding-right: 1.3rem !important;
}
.pl-half a{
  padding-left: 1.3rem !important;
}
.especial_border{
  border-radius: 10px;
  border: 1px solid transparent !important;
  transition: 0.3s all ease-in-out;
  padding: 5px  50px;
}
.especial_border:hover{
  border-radius: 10px;
  border: 1px solid #0090e7 !important;
}
</style>
